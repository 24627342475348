import Player, {Error} from '@vimeo/player';
// @ts-ignore
import inView from 'in-view';

export default class Videos {
    private videoSelector: string;
    private vimeoSelector: string;
    private inactiveClass: string;
    private activeClass: string;
    constructor(videoSelector: string, vimeoSelector:string, inactiveClass: string|null = null, activeClass: string|null = null) {
        this.videoSelector = videoSelector;
        this.vimeoSelector = vimeoSelector;
        this.inactiveClass = inactiveClass ?? 'inactive';
        this.activeClass = activeClass ?? 'active';
    }
    public init(): void {
        const videoWrappers: NodeList = document.querySelectorAll(this.videoSelector);
        const videoList: NodeList = document.querySelectorAll(this.videoSelector + " video");
        const vimeoWrappers: NodeList = document.querySelectorAll(this.vimeoSelector);
        const vimeoList: NodeList = document.querySelectorAll(this.vimeoSelector + ' .vimeo-video');
        let touchEvent: string = 'ontouchstart' in window ? 'touchstart' : 'click';
        if(videoWrappers) {
            videoWrappers.forEach(videoWrapper => {
                let videoWrapperEl = <HTMLElement> videoWrapper;
                let video: HTMLVideoElement|null = videoWrapperEl.querySelector('video');
                if(video) {
                    this.changeVideoClasses(videoWrapperEl, video);
                    video.addEventListener(touchEvent, e => {
                        if(video) {
                            let paused: boolean = video.paused;
                            if(paused) {
                                this.pauseAllOtherVideos(videoList, video);
                                vimeoWrappers.forEach(vimeoWrapper => {
                                    let vimeoWrapperElement: HTMLElement = <HTMLElement> vimeoWrapper;
                                    let vimeoElement: HTMLElement|null = <HTMLElement> vimeoWrapperElement.querySelector('.vimeo-video');
                                    if(vimeoElement && vimeoElement.player) {
                                        vimeoElement.player.pause();
                                        vimeoWrapperElement.classList.add('vimeo--inactive');
                                    }
                                })
                            }
                            paused ? video?.play() : video?.pause();
                            this.changeVideoClasses(videoWrapperEl, video);
                        }
                    })

                    video.addEventListener('ended', e => {
                        if (video) {
                            this.changeVideoClasses(videoWrapperEl, video);
                        }
                    })
                }
            })
        }
        // if(vimeoWrappers) {
        //     vimeoWrappers.forEach(vimeoWrapper => {
        //         let vimeoWrapperElement: HTMLElement = <HTMLElement> vimeoWrapper;
        //         let vimeo: HTMLElement|null = vimeoWrapperElement.querySelector('.vimeo-video');
        //         if(vimeo) {
        //             let vimeoActivated = false;
        //             let activateVimeoVideo = () => {
        //                 let player: Player = new Player(vimeo, {
        //                     background: true,
        //                     // color: '#00adef',
        //                 });
        //                 //ensuring paused to fix weird autoplay issue.
        //                 player.pause();
        //                 vimeo.player = player;
        //
        //                 player.on('play', () => {
        //                     if(vimeoWrapperElement.classList.contains('vimeo--fullscreen')) {
        //                         player.requestFullscreen().then(function() {
        //                             // the player entered fullscreen
        //                             player.play();
        //                         }).catch(function(error: Error) {
        //                             // an error occurred
        //                             console.error("Couldn't fullscreen video with error ", error);
        //                         });
        //                     } else {
        //                         player.play();
        //                     }
        //                     vimeoWrapperElement.classList.remove('vimeo--inactive');
        //                     vimeoWrappers.forEach(otherVimeoWrapper => {
        //                         let otherVideoWrapperElement: HTMLElement = <HTMLElement> otherVimeoWrapper;
        //                         let otherVimeoElement: HTMLElement|null = <HTMLElement> otherVideoWrapperElement.querySelector('.vimeo-video');
        //                         if(otherVimeoElement && vimeo !== otherVimeoElement) {
        //                             console.log(vimeo, " !== ", otherVimeoElement);
        //                             otherVimeoElement.player.pause();
        //                             otherVideoWrapperElement.classList.add('vimeo--inactive');
        //                         }
        //                     })
        //                     if(videoWrappers) {
        //                         videoWrappers.forEach(videoWrapper => {
        //                             let videoWrapperElement = <HTMLElement> videoWrapper;
        //                             let videoElement: HTMLVideoElement|null = videoWrapperElement.querySelector('video');
        //                             if(videoElement) {
        //                                 videoElement.pause();
        //                                 videoWrapperElement.classList.remove(this.activeClass);
        //                                 videoWrapperElement.classList.add(this.inactiveClass);
        //                             }
        //                         })
        //                     }
        //                 });
        //                 player.on('pause', () => {
        //                     vimeoWrapperElement.classList.add('vimeo--inactive');
        //                 })
        //                 player.on('ended', () => {
        //                     vimeoWrapperElement.classList.add('vimeo--inactive');
        //                     if(vimeoWrapperElement.classList.contains('vimeo--fullscreen')) {
        //                         player.exitFullscreen().then(function() {
        //                             // the player exits fullscreen
        //                         }).catch(function(error: Error) {
        //                             console.error("Couldn't fullscreen video with error ", error);
        //                         });
        //                     }
        //                 })
        //             }
        //             let checkVideo = () => {
        //                 if(vimeo && !vimeoActivated && this.isInView(vimeo)) {
        //                     // console.log('Videos.ts - vimeo video has entered viewport');
        //                     activateVimeoVideo();
        //                     vimeoActivated = true;
        //                 }
        //             }
        //             checkVideo();
        //             window.addEventListener('scroll', (e) => {
        //                 checkVideo()
        //             }, {passive: true})
        //         }
        //     })
        // }
        let autoplayVideos: NodeList = document.querySelectorAll('video[autoplay]');
        autoplayVideos.forEach(video => {
            let videoEl: HTMLVideoElement = <HTMLVideoElement> video;
            if(this.isInView(videoEl) && videoEl.paused) {
                videoEl.play();
            }

            if(!this.isInView(videoEl) && !videoEl.paused) {
                videoEl.pause();
            }

            window.addEventListener('scroll', (e) => {
                if(this.isInView(videoEl) && videoEl.paused) {
                    videoEl.play();
                }

                if(!this.isInView(videoEl) && !videoEl.paused) {
                    videoEl.pause();
                }
            }, {passive: true})
        })
    }
    private changeVideoClasses(wrapper: HTMLElement, video: HTMLVideoElement) : void {
        let playing: boolean = !video.paused;
        if(playing) {
            wrapper.classList.remove(this.inactiveClass);
            wrapper.classList.add(this.activeClass);
        } else {
            wrapper.classList.remove(this.activeClass);
            wrapper.classList.add(this.inactiveClass);
        }
    }
    private pauseAllOtherVideos(videoList: NodeList, currentVideo: HTMLVideoElement) {
        videoList.forEach(video => {
            let videoElement = <HTMLVideoElement> video;
            if(video !== currentVideo) {
                videoElement.pause();
                if (videoElement.parentElement) {
                    this.changeVideoClasses(videoElement.parentElement, videoElement);
                }
            }
        })
    }
    private isInView(el: HTMLElement, percentVisible: number = 10): boolean {
        let
            rect = el.getBoundingClientRect(),
            windowHeight = (window.innerHeight || document.documentElement.clientHeight);

        return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        )
    }
}