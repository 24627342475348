export default class TableAccordions {
    public accordionSelector: string = "tr .table__heading";
    constructor() {
    }

    init() {
        const accordions: NodeList = document.querySelectorAll(this.accordionSelector);
        accordions.forEach(accordion => {
            const accordionEl: HTMLElement = <HTMLElement> accordion;
            if(accordionEl.dataset && accordionEl.dataset.tableIndex) {
                let index: number = parseInt(accordionEl.dataset.tableIndex ?? 1);
                accordion.addEventListener('click', e => {
                    let accordionContent: NodeList = document.querySelectorAll('.table-accordion');
                    let currAccordionContent: HTMLElement | null = document.querySelector('#table-content-' + index);

                    accordionEl.firstElementChild?.classList.toggle('active');
                    accordions.forEach(otherAccordion => {
                        let otherAccordionEl: HTMLElement = <HTMLElement> otherAccordion;
                        if(accordionEl !== otherAccordionEl) {
                            otherAccordionEl.firstElementChild?.classList.remove('active');
                        }
                    })

                    accordionContent.forEach(content => {
                        if(content !== currAccordionContent) {
                            let contentEl: HTMLElement = <HTMLElement>content;
                            contentEl.classList.remove('active');
                        }
                    })

                    if(currAccordionContent) {
                        currAccordionContent.classList.toggle('active');
                    }
                })
            }
        })
    }
}