import Swiper, {EffectFade, SwiperOptions} from 'swiper';
export default class AccordionSlider {
    private swiperSelector: string = ".swiper";
    private swiperControlsSelector: string = ".swiper-control";
    private swiperWrapperSelector: string = ".media-swiper";
    private swiperWrappers: NodeList|null = null;
    private swiperEl: HTMLElement|null = null;
    private swiper: Swiper|null = null;
    private swiperControls: NodeList|null = null;
    public eventList: Array<string> = ["click", "mouseover"];
    public controlActiveClass: string = "active";
    constructor(swiperWrapperSelector: string|null = null, swiperSelector: string|null = null, swiperControlsSelector: string|null = null) {
        if(swiperWrapperSelector) {
            this.swiperWrapperSelector = swiperWrapperSelector;
        }
        if(swiperSelector) {
            this.swiperSelector = swiperSelector;
        }
        if(swiperControlsSelector) {
            this.swiperControlsSelector = swiperControlsSelector;
        }
        this.swiperWrappers = document.querySelectorAll(this.swiperWrapperSelector);
        // this.swiperEl = document.querySelector(this.swiperSelector);
        // this.swiperControls = document.querySelectorAll(this.swiperControlsSelector);
    }

    init() {
        if(this.swiperWrappers) {
            this.swiperWrappers.forEach(wrapper => {
                const wrapperEl: HTMLElement = <HTMLElement> wrapper;
                const swiperEl: HTMLElement|null = wrapperEl.querySelector(this.swiperSelector);
                if(swiperEl) {
                    // @ts-ignore
                    const options: SwiperOptions = {
                        modules: [ EffectFade ],
                        allowTouchMove: false,
                        fadeEffect: {
                            crossFade: true,
                        },
                        effect: "fade",
                    }
                    const swiper: Swiper = new Swiper(swiperEl, options);
                    const swiperControls: NodeList|null = wrapperEl.querySelectorAll(this.swiperControlsSelector);
                    swiperControls.forEach((control, i) => {
                        let index: number = control.dataset && control.dataset.index ? <number> control.dataset.index : i;
                        let controlEl = <HTMLElement> control;
                        this.eventList.forEach(event => {
                            controlEl.addEventListener(event, e => {
                                swiper?.slideTo(index);
                                controlEl.classList.add(this.controlActiveClass);
                                swiperControls?.forEach(otherControl => {
                                    let otherControlEl = <HTMLElement> otherControl;
                                    if(otherControlEl !== controlEl)
                                        otherControlEl.classList.remove(this.controlActiveClass);
                                })
                            })
                        })
                    })
                }
            })


            if(this.swiperControls && this.swiper) {
                this.swiperControls.forEach((control, i) => {
                    let index: number = control.dataset && control.dataset.index ? <number> control.dataset.index : i;
                    let controlEl = <HTMLElement> control;
                    this.eventList.forEach(event => {
                        controlEl.addEventListener(event, e => {
                            this.swiper?.slideTo(index);
                            controlEl.classList.add(this.controlActiveClass);
                            this.swiperControls?.forEach(otherControl => {
                                let otherControlEl = <HTMLElement> otherControl;
                                if(otherControlEl !== controlEl)
                                    otherControlEl.classList.remove(this.controlActiveClass);
                            })
                        })
                    })
                })
            }
        }
    }
}