export default class Accordions {
    private accordionSelector: string = ".accordion";
    public accordionActiveClass: string = "active";
    private accordions: NodeList;
    constructor(accordionSelector: string|null = null) {
        if(accordionSelector) {
            this.accordionSelector = accordionSelector;
        }

        this.accordions = document.querySelectorAll(this.accordionSelector);
    }

    init() {
        if(this.accordions) {
            this.accordions.forEach(accordion => {
                const accordionEl: HTMLElement = <HTMLElement> accordion;
                accordionEl.addEventListener('click', e => {
                    accordionEl.classList.add(this.accordionActiveClass);
                    this.accordions.forEach(otherAccordion => {
                        const otherAccordionEl: HTMLElement = <HTMLElement> otherAccordion;
                        if(otherAccordionEl !== accordionEl)
                            otherAccordionEl.classList.remove(this.accordionActiveClass);
                    })
                });
            })
        }
    }
}