import {enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks} from "body-scroll-lock";

export default class Navigation {
    public menuActiveClass: string = "active";
    private navBar: HTMLElement;
    private header: HTMLElement;
    private logo: HTMLElement;
    private menu: HTMLElement;
    private burger: HTMLElement;
    private close: HTMLElement;
    private fixedClass: string;
    private logoSrc: string;
    private fixedLogoSrc: string | null;
    private menuSelector: string;
    private burgerSelector: string;
    private closeSelector: string;
    constructor(navBarSelector: string = '#header', headerSelector: string = '.header', logoSelector: string = '#logo img', fixedClass: string = "fixed",
                menuSelector: string = "#menu-wrapper", burgerSelector: string = "#burger", closeSelector: string = "#close")  {
        const navBar = document.querySelector(navBarSelector);
        if(!navBar) throw new Error('Navigation.ts - navBar selector not found');
        const header = document.querySelector(headerSelector);
        if(!header) throw new Error('Navigation.ts - Header selector not found');
        const logo = document.querySelector(logoSelector);
        if(!logo) throw new Error("Navigation.ts - Logo selector not found");
        this.menuSelector = menuSelector;
        const menu = document.querySelector(this.menuSelector);
        if(!menu) throw new Error("Navigation.ts - Menu selector not found");
        this.burgerSelector = burgerSelector;
        const burger = document.querySelector(this.burgerSelector);
        if(!burger) throw new Error("Navigation.ts - burger button not found");
        this.closeSelector = closeSelector;
        const close = document.querySelector(this.closeSelector);
        if(!close) throw new Error("Navigation.ts - close button not found");

        this.navBar = navBar;
        this.header = header;
        this.logo = logo;
        this.menu = menu;
        this.burger = burger;
        this.close = close;
        this.fixedClass = fixedClass;
        this.logoSrc = this.logo.src;
        this.fixedLogoSrc = this.logo.dataset && this.logo.dataset.logoFixed ? this.logo.dataset.logoFixed : null;
    }
    public init() : void {
        const scrollHeight: number = this.header.getBoundingClientRect().height;
        if(this.navBar) {
            this.fixNavigation(scrollHeight);
            document.addEventListener('scroll', e => {
                this.fixNavigation(scrollHeight);
            }, {passive: true});

            this.burger.addEventListener('click', e => {
                this.menu.classList.add(this.menuActiveClass);
                disableBodyScroll(this.menu);
            })

            this.close.addEventListener('click', e => {
                this.menu.classList.remove(this.menuActiveClass);
                enableBodyScroll(this.menu);
            })
        }
    }
    private fixNavigation(scrollHeight: number) : void {
        let scrollY: number = window.pageYOffset;
        if(this.shouldFix(scrollY, scrollHeight)) {
            this.navBar.classList.add(this.fixedClass);
            if(this.fixedLogoSrc) {
                this.logo.src = this.fixedLogoSrc;
            }
        } else {
            this.navBar.classList.remove(this.fixedClass);
            if(this.fixedLogoSrc) {
                this.logo.src = this.logoSrc;
            }
        }
    }
    /**
     * @param currScroll The current scroll on the page
     * @param requiredScroll The scroll required to fix the navigation
     * @returns {boolean} whether to fix the navigation
     * @private
     */
    private shouldFix(currScroll: number, requiredScroll: number) : boolean {
        return currScroll > requiredScroll;
    }
}