export default class Submenu {
    private submenuButtons: NodeList;
    private submenuActiveClass: string;

    public moreLinksButtonSelector: string = '#more-links > a';
    public moreLinksSelector: string = ".more-links__wrapper";

    constructor(submenuButtonSelector: string, submenuActiveClass: string) {
        let submenuButtons = document.querySelectorAll(submenuButtonSelector);
        if(!submenuButtons) throw new Error("Submenu.ts - no submenu buttons found on page");
        submenuButtons.forEach((btn) => {
            let submenu: Element | null = btn.nextElementSibling;
            if(!submenu || submenu.tagName !== "UL")
                throw new Error('Submenu.ts - Submenu button doesn\'t contain a corresponding submenu');
        })
        this.submenuButtons = submenuButtons;
        this.submenuActiveClass = submenuActiveClass;
    }
    public init() : void {
        this.submenuButtons.forEach(btn => {
            btn.addEventListener('click', (e) => { this.submenuClick(e) });
        })

        const moreLinksButton: HTMLElement | null = document.querySelector(this.moreLinksButtonSelector);
        const moreLinks: HTMLElement | null = document.querySelector(this.moreLinksSelector);
        if(moreLinks && moreLinksButton) {
            moreLinksButton.addEventListener('click', e => {
                e.preventDefault();
                moreLinks.classList.toggle('active');
                moreLinksButton.classList.toggle('active');
                this.submenuButtons.forEach(btn => {
                    const el: HTMLElement = <HTMLElement> btn;
                    el.classList.remove('active');
                })
            })
        }

        document.addEventListener('click', e => {
            const el: Element = <Element>e.target;
            let close: boolean = this.outsideClicked(el);
            if(close) {
                this.submenuButtons.forEach(btn => {
                    const submenu: Element | null = btn.nextElementSibling;
                    btn.classList.remove(this.submenuActiveClass);
                    if(submenu) {
                        submenu.classList.remove('active');
                    }
                })
            }

            if(moreLinks && moreLinksButton) {
                if (!(el === moreLinks || el === moreLinksButton || el.closest('.more-links__wrapper'))) {
                    moreLinks.classList.remove("active");
                    moreLinksButton.classList.remove("active");
                }
            }
        })
    }
    private submenuClick(event: Event) : void {
        event.preventDefault();
        const btn: EventTarget | null = event.target;
        if(btn) {
            btn.classList.toggle(this.submenuActiveClass);
            btn.nextElementSibling.classList.toggle('active');
        }

        // const moreLinksButton: HTMLElement | null = document.querySelector(this.moreLinksButtonSelector);
        // const moreLinks: HTMLElement | null = document.querySelector(this.moreLinksSelector);
        // if(moreLinksButton && moreLinks) {
        //     moreLinks.classList.remove('active');
        //     moreLinksButton.classList.remove('active');
        // }
    }
    private outsideClicked(el : Element) : boolean {
        let isBtn: boolean = false;
        let submenuButtonArray: Array<Node> = Array.from(this.submenuButtons);
        for(let i: number = 0; i < submenuButtonArray.length; i++) {
            let submenuBtn: Node = submenuButtonArray[i];
            let submenu: Element = submenuButtonArray[i].nextElementSibling;
            if(el === submenuBtn || el === submenu || el.closest('ul.sub-menu')) {
                isBtn = true;
                break;
            }
        }
        return !isBtn;
    }
}