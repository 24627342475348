import {getScrollBarWidth} from './utils';

export default class StatsVideoSize {
    constructor() {
        const statWrapper: HTMLElement|null = document.querySelector('.stats');
        if(statWrapper) {
            const direction: string = statWrapper.dataset.direction ? statWrapper.dataset.direction.toLowerCase() : 'right';
            const statsMediaWrapper: HTMLElement|null = statWrapper.querySelector('.stats__content');
            if (statsMediaWrapper) {
                const updateVideoSizes = () => {
                    let videoRect: DOMRect = statsMediaWrapper.getBoundingClientRect();
                    let videoWrapperWidth: number = videoRect.width;
                    let windowWidth: number = window.innerWidth - getScrollBarWidth();
                    let distanceToSide: number = direction === 'left' ? videoRect.left : windowWidth - videoRect.right;
                    let videoWidth: number = videoWrapperWidth + distanceToSide;
                    document.documentElement.style.setProperty('--stat-video', videoWidth + "px");
                    document.documentElement.style.setProperty('--stat-offset', distanceToSide + "px");
                    console.log("StatsVideoSize.ts - Video width: " + videoWidth + "px");
                    console.log("StatsVideoSize.ts - distance: " + distanceToSide + "px");
                }

                updateVideoSizes();
                window.addEventListener('resize', e => { updateVideoSizes() });
            }
        }
    }
}