export default class FooterMenus {
    public footerMenuSelector: string = ".footer__nav-row";
    private footerMenus: NodeList | null = null;
    public activeClass: string = "active";
    init() {
        this.footerMenus = document.querySelectorAll(this.footerMenuSelector);
        if(this.footerMenus) {
            this.footerMenus.forEach(row => {
                const rowElement: HTMLElement = <HTMLElement> row;
                const rowButton: Element | null = rowElement.firstElementChild;
                const rowMenu: HTMLUListElement | null = rowElement.querySelector("ul");
                if(rowButton && rowMenu) {
                    rowButton.setAttribute("role", "button");
                    rowButton.addEventListener("click", e => {
                        rowMenu.classList.toggle(this.activeClass);
                        rowButton.classList.toggle(this.activeClass);
                    });
                }
            })
        }
    }
}