import Navigation from './modules/Navigation';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Submenu from "./modules/Submenu";
import FooterMenus from './modules/FooterMenus';
import Videos from "./modules/Videos";
import AccordionSlider from './modules/AccordionSlider';
import Accordions from './modules/Accordions';
import StatsVideoSize from './modules/StatsVideoSize';
import Graph from './modules/Graph';
import Swiper, {EffectFade, SwiperOptions, Pagination, Autoplay} from "swiper";
import TableAccordions from './modules/TableAccordions';
import {LiteVimeoEmbed} from "@slightlyoff/lite-vimeo";
import LazyLoad from 'vanilla-lazyload';
// import Tapfiliate from './modules/tapfiliate';
// @ts-ignore
window.namespace = {
    init: function() {
        new LazyLoad();
        const navigation: Navigation = new Navigation('#header', 'header', '#logo img','fixed', "#menu-wrapper", '#burger', '#close');
        navigation.init();
        const submenu: Submenu = new Submenu('.menu-toggle', 'menu-open');
        submenu.init();
        const footerMenus: FooterMenus = new FooterMenus();
        footerMenus.init();
        const videos: Videos = new Videos('.video-controls', '.vimeo', 'video-controls--inactive', 'video-controls--active');
        videos.init();
        const accordionSlider = new AccordionSlider('.media-swiper', '.media-swiper__swiper', '.swiper-control');
        accordionSlider.init();
        const accordions = new Accordions('.accordion');
        accordions.init();
        new StatsVideoSize();
        new Graph();
        let table: TableAccordions = new TableAccordions();
        table.init();
        new LiteVimeoEmbed;
        new Tapfiliate;

        const options: SwiperOptions = {
            modules: [Pagination, EffectFade, Autoplay],
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"><i class="page-dot"></i></span>';
                },
            },
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            loop: true,
            // autoplay: {
            //     delay: 5000
            // },
        }
        const swiper: Swiper = new Swiper('.quotes__swiper', options);
        if(window.scrollX > 0) {
            window.scrollTo(0, window.scrollY);
        }

        const categorySwiper: Swiper = new Swiper('#categories-mobile', {
            slidesPerView: "auto",
        });

        if(window.innerWidth < 1199) {
            const headerCategorySwiper: Swiper = new Swiper('#header-categories', {
                slidesPerView: "auto",
            });
        }

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                const id: string = this.getAttribute('href');
                const yOffset: number = -100;
                const element: HTMLElement | null = document.querySelector(id);

                if(element) {
                    const y: number = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                }
            });
        });
    },
    helpers: {

    }
}

// @ts-ignore
window.namespace.init();