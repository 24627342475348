import BScroll from '@better-scroll/core';
export default class Graph {
    public pointSelector: string = ".point";
    public activePointClass: string = "active";
    public inactivePointClass: string = "inactive";
    public nextButtonSector: string = "#graph-next";
    public prevButtonSector: string = "#graph-prev";
    public wrapperSelector: string = ".graph__wrapper";
    private scroll: BScroll|null = null;
    private wrapper: HTMLElement|null = null;
    private nextButton: HTMLButtonElement|null = null;
    private prevButton: HTMLButtonElement|null = null;
    private points: NodeListOf<HTMLElement>|null;
    private currentPoint: number = 1;
    constructor(pointSelector: string|null = null) {
        if(pointSelector) {
            this.pointSelector = pointSelector;
        }
        this.points = document.querySelectorAll(this.pointSelector);
        this.wrapper = document.querySelector(this.wrapperSelector);
        if(this.wrapper && window.innerWidth < 1200) {
            this.scroll = new BScroll(this.wrapper, {
                freeScroll: false,
                scrollX: true,
                scrollY: false,
                bounce: false,
                momentum: false,
                outOfBoundaryDampingFactor: 1000,
                eventPassthrough: 'vertical',
            });

            let currActivePoint: HTMLElement | null = document.querySelector(this.pointSelector + `.${this.activePointClass}`);
            if(currActivePoint) {
                this.scrollGraph(currActivePoint);
            }
        }
        if(this.points) {
            this.init();
        }
    }

    private init() {
        this.points?.forEach(point => {
            point.addEventListener('click', e => {
                this.removeCurrentActivePoint();
                this.currentPoint = this.updateCurrentIndex(parseInt(point.dataset.point ?? "1"));
                point.classList.remove(this.inactivePointClass);
                point.classList.add(this.activePointClass);
                this.scrollGraph(point);
            });
        })

        let activePoint : HTMLElement | null = document.querySelector('.point.active');
        if(activePoint) {
            this.graphContentUpdate(activePoint);
        }

        this.nextButton = document.querySelector(this.nextButtonSector);
        this.prevButton = document.querySelector(this.prevButtonSector);
        if(this.nextButton) {
            this.nextButton.addEventListener('click', e => {
                if(!this.nextButton?.disabled) {
                    this.removeCurrentActivePoint();
                    this.currentPoint = this.updateCurrentIndex(this.currentPoint + 1);
                    let nextPoint: HTMLElement|null = document.querySelector(`.point[data-point='${this.currentPoint}']`);
                    if(nextPoint) {
                        nextPoint.classList.remove(this.inactivePointClass);
                        nextPoint.classList.add(this.activePointClass);
                        this.scrollGraph(nextPoint);
                    }
                }
            });
        }
        if(this.prevButton) {
            this.prevButton.addEventListener('click', e => {
                if(!this.prevButton?.disabled) {
                    this.removeCurrentActivePoint();
                    this.currentPoint = this.updateCurrentIndex(this.currentPoint - 1);
                    let nextPoint: HTMLElement|null = document.querySelector(`.point[data-point='${this.currentPoint}']`);
                    if(nextPoint) {
                        nextPoint.classList.remove(this.inactivePointClass);
                        nextPoint.classList.add(this.activePointClass);
                        this.scrollGraph(nextPoint);
                    }
                }
            });
        }
    }

    private removeCurrentActivePoint() : boolean {
        let currActivePoint: HTMLElement | null = document.querySelector(this.pointSelector + `.${this.activePointClass}`);
        if(currActivePoint) {
            currActivePoint.classList.remove(this.activePointClass);
            currActivePoint.classList.add(this.inactivePointClass);
            return true;
        }
        return false;
    }

    private updateCurrentIndex(navigateTo: number) : number {
        let navigationNumber: number = navigateTo;
        if(this.points) {
            if (navigateTo >= this.points?.length) {
                if(this.nextButton) {
                    this.nextButton.disabled = true;
                }

                return this.points?.length;
            }

            if(navigateTo <= 1) {
                if(this.prevButton) {
                    this.prevButton.disabled = true;
                }
                return 1;
            }

            if(this.nextButton) {
                this.nextButton.disabled = false;
            }

            if(this.prevButton) {
                this.prevButton.disabled = false;
            }
        }

        return navigationNumber
    }

    private scrollGraph(point: HTMLElement) : void {
        if(this.scroll && this.wrapper) {
            console.log("Max Scroll X: " + this.scroll.maxScrollX);
            console.log("Min Scroll X: " + this.scroll.minScrollX);
            let parent: Element|null = this.wrapper.firstElementChild;
            if(parent) {
                let parentRect: DOMRect = parent.getBoundingClientRect(),
                    graphRect: DOMRect = this.wrapper.getBoundingClientRect(),
                    graphWidth: number = graphRect.width,
                    wrapperWidth: number = parentRect.width,
                    pointRect: DOMRect = point.getBoundingClientRect(),
                    offset: number = (pointRect.left - parentRect.left) - 40,
                    maxScroll: number = wrapperWidth - graphWidth;
                console.log("maxScroll: ", maxScroll);

                let scrollTo: number = offset < maxScroll ? offset : maxScroll;
                this.scroll.scrollTo(-scrollTo, 0, 200);
                this.graphContentUpdate(point);
            }
            // this.scroll.scrollToElement(point, 200, 0, 0)
        }
    }

    graphContentUpdate(point: HTMLElement) : void {
        //Updating the graph content on mobile.
        const title: HTMLElement | null = point.querySelector('.point__title');
        const copy: HTMLElement | null = point.querySelector('.point__copy');
        const link: HTMLElement | null = point.querySelector('.point__link');
        let graphTitle: HTMLElement | null = document.querySelector('#graph-title');
        let graphCopy: HTMLElement | null = document.querySelector('#graph-content');
        let graphLink: HTMLElement | null = document.querySelector('#graph-link');

        if(title && graphTitle) {
            graphTitle.innerHTML = title.innerHTML;
        } else {
            if(graphTitle) { graphTitle.innerHTML = "" }
        }

        if(copy && graphCopy) {
            graphCopy.innerHTML = copy.innerHTML;
        } else {
            if(graphCopy) { graphCopy.innerHTML = "" }
        }

        if(link && graphLink) {
            graphLink.innerHTML = link.innerHTML;
        } else {
            if(graphLink) { graphLink.innerHTML = "" }
        }
    }
}